import SCDataLayer from '@lib/gtm/SCDataLayer'
import segmentAnalytics from '@lib/segment/analytics'
import checkoutAnalytics from '@lib/gtm/checkoutAnalytics'
import UserDTO from '@concepts/Auth/dto/UserDTO'
import { setCookie } from '@utils/cookies'

import orderRedirect from '../utils/orderRedirect'
import { Cart } from '@concepts/Cart/types/cart'
import { Order } from '@concepts/OrderConfirmation/types/order'

export type useOrderSuccessfulType = {
  orderSuccessful: Function
}

export type OrderSuccessful = {
  order: Order
  cart: Cart
  gateway: string
  gtoken?: string
  uidocp?: string
}

const useOrderSuccessful = (): useOrderSuccessfulType => {
  const trackEvents = (order: Order, cart: Cart, gateway: string): void => {
    const totalInCents = order.priceInCents

    checkoutAnalytics.clickedCompleteOrder(gateway, totalInCents)

    checkoutAnalytics.checkoutCompleteOrder(gateway, totalInCents)

    segmentAnalytics.identifyWithTraits({
      user: UserDTO.parse(order.user),
      customParams: {
        publisher_id: order.publisher?.databaseId as number
      }
    })

    SCDataLayer.trackPurchase(order)

    if (!order.isAllFreebie) segmentAnalytics.orderCompleted(order, cart)
  }

  const orderSuccessful = ({
    order,
    cart,
    gateway,
    gtoken,
    uidocp
  }: OrderSuccessful): void => {
    const orderId = order.databaseId

    trackEvents(order, cart, gateway)

    // TODO: We should abandon this cookie when transition from Legacy is totally complete
    setCookie(`order_success_${orderId}`, 'true')

    orderRedirect({ id: orderId, gtoken, uidocp })
  }

  return {
    orderSuccessful
  }
}

export default useOrderSuccessful

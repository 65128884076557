import { useGlobalContext } from '@config/contexts'
import { getCartIdFromCookies } from '@config/cookies'
import { useApollo } from '@lib/apollo'
import { isPresent } from '@utils/logic'
import { Dispatch, useCallback, useState } from 'react'
import CurrentCartRepository from '../repository/CurrentCartRepository'
import { setCart, setLoading } from '../store/actions'
import useCartFormatter from './useCartFormatter'
import useCartNotify from './useCartNotify'
import { Address, CartItemSource } from 'src/generated/graphql'
import AddItemToCart from '@concepts/Cart/app/AddItemToCart'
import RemoveItemFromCart from '../app/RemoveItemFromCart'
import { productAddedToCartAnalytics } from '../app/CartAnalytics'
import PrepareCartForCheckout, {
  PrepareCartForCheckoutResult
} from '../app/PrepareCartForCheckout'
import { CartState } from '../types/cart'
import { Action } from '../types/actions'
import noop from '@utils/noop'
import postCartUpdate from '../utils/postCartUpdate'
import { pathOr } from 'ramda'
import { Maybe } from 'graphql/jsutils/Maybe'

type SyncAttributes = {
  gateway?: string
  item?: Record<string, number | string> | undefined
  coupon?: string | undefined
  isApplyCredits?: boolean
  addresses?: Record<string, Address | undefined>
}

type SetCartItemProps = {
  id: number
  quantity: number
  saleId: number
  source: string
  addresses?: {
    shipping: Address
    billing: Address
  }
}

export type AddSaleToCartProps = {
  saleId?: Maybe<number>
  saleSlug: string
  source?: CartItemSource
  quantity?: number
  childSaleId?: number | null
  bidPrice?: number | null
  priceToken?: string
  maxPerUser?: number
  fromUpsell?: boolean
}

type UseCartOperations = {
  addSaleToCart: (params: AddSaleToCartProps) => void
  updateCart: Function
  setCartItem: Function
  removeSaleFromCart: Function
  syncCartForCheckout: Function
  setCartShippingAddress: Function
}

const getAddressesPayload = (
  shipping?: Address,
  billing?: Address
): Record<string, Address | undefined> => {
  return { shipping, billing }
}

const useCartOperations = (
  state: CartState,
  dispatch: Dispatch<Action>
): UseCartOperations => {
  const apolloClient = useApollo()
  const { removeItemFromCart } = useCartFormatter()
  const { hostname, publisher } = useGlobalContext()
  const [encryptedCartId, setEncryptedCartId] = useState(getCartIdFromCookies())
  const { showError, showExpiredSalesInfo } = useCartNotify()

  const removeSaleFromCart = useCallback(
    async (cartItemId: number): Promise<void> => {
      if (!encryptedCartId || !state.cart) return
      const result = await RemoveItemFromCart.remove({
        encryptedCartId,
        cartItemId,
        client: apolloClient
      })

      if (result.success && result.cartItem) {
        const currentCart = await CurrentCartRepository.find(
          { encryptedCartId },
          apolloClient
        )

        const newCart = removeItemFromCart(currentCart, result.cartItem)

        dispatch(setCart({ ...state.cart, ...newCart }))

        postCartUpdate(newCart)
      } else {
        showError(result.errors)
      }
    },

    [
      apolloClient,
      removeItemFromCart,
      state.cart,
      encryptedCartId,
      showError,
      dispatch
    ]
  )

  const syncCartForCheckout = useCallback(
    async (
      syncAttrs: SyncAttributes
    ): Promise<PrepareCartForCheckoutResult> => {
      return PrepareCartForCheckout.apply(
        {
          gateway: syncAttrs.gateway,
          attributes: {
            cart: state.cart,
            coupon:
              syncAttrs.coupon ??
              pathOr(undefined, ['codes', '0', 'code'], state.coupons),
            applyCredits: syncAttrs.isApplyCredits ?? state.credit.isApplied,
            item: syncAttrs.item
          },
          shippingAddress: syncAttrs.addresses?.shipping,
          billingAddress: syncAttrs.addresses?.billing,
          encryptedCartId: getCartIdFromCookies()
        },
        apolloClient
      )
    },
    [state, apolloClient]
  )

  const updateCart = useCallback(
    async (attrs: SyncAttributes): Promise<object> => {
      try {
        dispatch(setLoading(true))

        const result = await syncCartForCheckout(attrs)

        const { cart, summary, expiredSales } = result

        dispatch(
          setCart({
            ...state.cart,
            ...cart,
            summary
          })
        )

        if (isPresent(expiredSales)) showExpiredSalesInfo(expiredSales)
        postCartUpdate(cart)

        return result
      } finally {
        dispatch(setLoading(false))
      }
    },
    [dispatch, state.cart, showExpiredSalesInfo, syncCartForCheckout]
  )

  const setCartItem = useCallback(
    async ({
      id,
      quantity,
      saleId,
      source,
      addresses
    }: SetCartItemProps): Promise<void> => {
      const attrs = {
        item: { id, quantity, saleId, source },
        addresses
      }

      await updateCart(attrs).catch(noop)
    },
    [updateCart]
  )

  const setCartShippingAddress = useCallback(
    async (gateway: string, address: Address): Promise<object> => {
      const addresses = getAddressesPayload(address, address)

      const attrs = {
        gateway,
        addresses
      }

      return updateCart(attrs)
    },
    [updateCart]
  )

  const addSaleToCart = useCallback(
    async ({
      saleId,
      saleSlug,
      source,
      quantity,
      childSaleId,
      bidPrice,
      priceToken,
      maxPerUser,
      fromUpsell
    }: AddSaleToCartProps): Promise<void> => {
      dispatch(setLoading(true))

      const result = await AddItemToCart.add({
        saleId,
        saleSlug,
        quantity,
        childSaleId,
        bidPrice,
        priceToken,
        maxPerUser,
        source,
        encryptedCartId,
        client: apolloClient
      })

      if (!encryptedCartId) setEncryptedCartId(result.encryptedCartId)

      if (isPresent(result.expiredSales))
        showExpiredSalesInfo(result.expiredSales)

      if (result.success && result.cartItem) {
        productAddedToCartAnalytics({
          operationResult: result,
          hostname,
          fromUpsell,
          publisherId: publisher?.databaseId as number
        })

        await setCartItem({
          id: result.cartItem.id,
          quantity: result.cartItem.quantity,
          saleId: result.cartItem.sale.id,
          source: result.cartItem.source
        })
      } else {
        showError(result.errors)
      }
      dispatch(setLoading(false))
    },
    [
      dispatch,
      encryptedCartId,
      apolloClient,
      showExpiredSalesInfo,
      hostname,
      publisher?.databaseId,
      setCartItem,
      showError
    ]
  )

  return {
    addSaleToCart,
    updateCart,
    setCartItem,
    removeSaleFromCart,
    syncCartForCheckout,
    setCartShippingAddress
  }
}

export default useCartOperations
